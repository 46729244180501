<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Junio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <section class="d-flex">
      <!-- Filtrar por nombre del grupo -->
      <v-text-field class="me-2 campo" v-model="buscar.grupo" label="Grupo" outlined dense hide-details color="primary">
      </v-text-field>
      
      <!-- Filtrar por tipo -->
      <v-autocomplete class="me-2 campo" v-model="buscar.tipo" label="Tipo" outlined dense hide-details color="primary"
        :items="tipoPreguntas" :menu-props="{ offsetY: true }">
      </v-autocomplete>
      
      <!-- Filtrar por estado -->
      <v-autocomplete class="me-2 campo" v-model="buscar.estado" label="Estado" outlined dense hide-details color="primary"
        :items="estados" :menu-props="{ offsetY: true }">
      </v-autocomplete>
      
      <!-- Este botón abre un diálogo para agregar un grupo de preguntas con sus respectivas preguntas-->
      <div>
        <v-tooltip left color="success" :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab small color="success" v-on="on" v-bind="attrs" 
              @mousedown.prevent="dialogoPreguntas = true">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Agregar</span>
        </v-tooltip>
      </div>
    </section>
    
    <v-container class="contenedor-cards ma-0 pa-0 pt-4">
      <!-- Cards que contienen la información de los grupos de preguntas -->
      <v-row class="ma-0">
        <v-col v-if="cardGrupos.items.length > 0" class="pa-0">
          <v-card class="mb-1 grey lighten-2">
            <v-card-text class="font-weight-black d-flex align-center black--text">
              <v-row class="pe-4">
                <v-col cols="12" md="3" class="d-flex justify-center pa-0">GRUPO</v-col>
                <v-col cols="12" md="7" class="d-flex justify-center pa-0">PREGUNTAS</v-col>
                <v-col cols="12" md="2" class="d-flex justify-center pa-0">ACCIONES</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="cardGrupos.items.length > 0" cols="12" md="12" class="pa-0 contenido-cards">
          <draggable v-model="cardGrupos.items" @end="cambiarPrioridadGrupo" tag="div" ghost-class="ghost" chosen-class="chosen" animation="100" :disabled="!mover">
            <v-card border class="mb-1 elevation-2" v-for="item in cardGrupos.items" :key="item.idGrupoPregunta">
              <v-card-text>
                <v-row>
                  <!-- Primer columna con la información general -->
                  <v-col cols="12" md="3" class="d-flex align-center">
                    <v-row class="ps-2">
                      <v-col cols="12" md="11" class="pa-1">
                        <span class="info-card text-capitalize">{{ item.priorizacion }}. {{ item.descripcion }}</span>
                      </v-col>
                      <v-col cols="12" md="11" class="pa-1">
                        <span class="info-card">Tipo: </span><span class="black--text">{{ obtenerTipo(item.tipoPreguntaValue) }}</span>
                      </v-col>
                      <!-- <v-col cols="12" md="11" class="pa-1">
                        <span class="info-card">Estado: </span> 
                        <span class="font-weight-black" :class="item.eliminado === false ? 'success--text' : 'error--text'">
                          {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </span>
                      </v-col> -->
                    </v-row>
                  </v-col>

                  <!-- Segunda columna con la descripción -->
                  <v-col cols="12" md="7" class="d-flex align-center">
                    <v-row class="pe-2">
                      <v-col cols="12" md="12" class="pa-0">
                        <ol>
                          <li v-for="pregunta in item.preguntas" :key="pregunta.idPregunta" :class="!pregunta.eliminado? 'black--text':'--text'">
                            <v-card-text class="pt-0 pl-0 pr-0 pb-1" 
                            >{{ pregunta.descripcion }}</v-card-text>
                          </li>
                        </ol>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- Tercera columna de las acciones-->
                  <v-col cols="12" md="2" align="center" class="d-flex align-center">
                    <v-row class="pe-2">
                      <v-col cols="12" md="12" class="pa-0">
                        <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'grey'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ms-2 me-2" icon fab small v-on="on" v-bind="attrs" :color="item.eliminado === false ? 'orange' : 'grey'">
                              <v-icon @click="item.eliminado === false && abrirDialogoEditar(item)">border_color</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ item.eliminado === false ? 'Editar' : 'Grupo inactivo' }}</span>
                        </v-tooltip>

                        <!-- <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'success'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ms-2 me-2" icon fab small v-on="on" v-bind="attrs" :color="item.eliminado === false ? 'error' : 'success'">
                              <v-icon @click="abrirDialogoEstado(item)">{{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
                        </v-tooltip> -->

                        <v-tooltip bottom :color="item.eliminado === false ? 'blue' : 'grey'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ms-2 me-2" icon fab small v-on="on" v-bind="attrs" :color="item.eliminado === false ? 'blue' : 'grey'">
                              <v-icon @click="item.eliminado === false && abrirDialogoCambiarGrupo(item)">swap_horiz</v-icon>
                            </v-btn>
                          </template>
                          <span class="d-flex text-center">{{ item.eliminado === false ? 'Cambiar preguntas de grupo' : 'Grupo inactivo' }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </draggable>
        </v-col>
        <v-col v-else cols="12" md="12" class="mt-4 pa-0 d-flex justify-center">
          <span>No se encontraron resultados.</span>
        </v-col>
      </v-row>

      <!-- Paginado -->
      <v-row v-if="cardGrupos.items.length > 0" class="paginado">
        <span class="me-8">Items por página:</span>
        <div>
          <v-select class="me-6 select" v-model="cardGrupos.itemsPerPage" hide-details dense outlined
            :items="cardGrupos.opciones">
          </v-select>
        </div>

        <span class="items"> {{ rangoItems }} de {{ cardGrupos.totalElements }}</span>
        <v-btn icon fab small :disabled="cardGrupos.page === 1" @click="cardGrupos.page = 1">
          <v-icon medium>first_page</v-icon>
        </v-btn>
        <v-btn icon fab small :disabled="cardGrupos.page === 1" @click="cardGrupos.page = cardGrupos.page - 1">
          <v-icon medium>chevron_left</v-icon>
        </v-btn> 
        {{ cardGrupos.page }}
        <v-btn icon fab small :disabled="cardGrupos.page === cardGrupos.totalPage" @click="cardGrupos.page = cardGrupos.page + 1">
          <v-icon medium>chevron_right</v-icon>
        </v-btn>
        <v-btn icon fab small :disabled="cardGrupos.page === cardGrupos.totalPage" @click="cardGrupos.page = cardGrupos.totalPage">
          <v-icon medium>last_page</v-icon>
        </v-btn>
      </v-row>
    </v-container>

    <!-- dialogo para crear/editar un grupo de preguntas con sus preguntas -->
    <v-dialog v-model="dialogoPreguntas" transition="dialog-bottom-transition" max-width="45rem" persistent :editar="false">
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ tituloDialogo }} </span>
        </v-card-title>
        <v-card-text class="pt-5 size-card">
          <v-form ref="preguntasForm">
            <div class="d-flex">
              <!-- Campo para la descripción -->
              <v-text-field class="me-2 size" v-model="crearGrupo.descripcion" label="Descripción" dense outlined color="primary"
                :rules="rules.required">
              </v-text-field>

              <!-- Campo para el tipo -->
              <v-select class="size" v-model="crearGrupo.tipo" label="Tipo" required dense outlined color="primary"
                :rules="rules.required" :items="tipoPreguntas" :menu-props="{ offsetY: true }">
              </v-select>
            </div>

            <v-container>
              <v-row>
                <v-col cols="12" align="center" class="pt-0">
                  <span class="text-h6 preguntas">Preguntas</span>
                  <div class="linea"></div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <draggable v-model="crearGrupo.preguntas" @end="cambiarPrioridadPreguntas" tag="div" ghost-class="ghost" chosen-class="chosen" animation="300">
                    <v-row v-for="(pregunta, index) in crearGrupo.preguntas" :key="index">
                      <v-col cols="11" class="pa-0 align-content-center d-flex">
                        <v-textarea :rows="1" :label="`Pregunta ${index + 1}`" :disabled="pregunta.eliminado"
                        auto-grow row-height="15" multi-line v-model="pregunta.descripcion" dense outlined :rules="index === 0 ? rules.required : []" color="primary"
                        :error-messages="pregunta.error" @input="validarDuplicados()">
                        </v-textarea>
                        <v-tooltip bottom :color="pregunta.eliminado === false ? 'error' : 'success'">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="!pregunta.createdby"  class="ms-2 me-2" icon fab small v-on="on" v-bind="attrs" :color="pregunta.eliminado === false ? 'error' : 'success'">
                              <v-icon @click="cambiarEstadoPregunta(pregunta)">{{ pregunta.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ pregunta.eliminado === false ? 'Inactivar' : 'Activar' }} </span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="1" class="pa-0 d-flex justify-center">
                        <v-btn v-if="index > 0 && !pregunta.createdby" color="error" icon fab small @click="eliminarCampoPregunta(index)">
                          <v-icon>remove</v-icon>
                        </v-btn>
                        <v-btn v-if="index == 0" color="success" icon fab small @click="agregarCampoPregunta" :disabled="!preguntasLlenas">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>

            <!-- Botones del formulario -->
            <div class="d-flex justify-end">
              <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
              <v-btn :color="!formValid ? 'grey' : 'success'" :disabled="!formValid" depressed
                @click="!editar ? agregarGrupo() : editarGrupo()">Guardar
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialogo para cambiar el estado -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ mensajeEstado }} </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
            <v-btn color="success" depressed @click="cambiarEstado()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dialogo para cambiar las preguntas de un grupo de preguntas -->
    <v-dialog v-model="dialogoCambiarGrupo" transition="dialog-bottom-transition" max-width="50rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> Cambiar preguntas de grupo </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="d-flex">
            <div class="me-2 size">
              <v-text-field  v-model="preguntasSeleccionadas.descripcion" readonly outlined dense hide-details color="primary">
              </v-text-field>

              <draggable v-model="grupoUno" tag="div" ghost-class="ghost" chosen-class="chosen" animation="300" group="pregunta" @change="moverPregunta">
                <v-card border v-for="item in grupoUno" :key="item.idPregunta" class="mt-2 mb-2 pa-2 elevation-2">
                  {{ item.descripcion }}
                </v-card>
              </draggable>
            </div>
            <div class="ms-2 size">
              <v-autocomplete v-model="grupoDestino" outlined dense hide-details label="Mover pregunta a" :items="grupos" @change="traerPreguntas" color="primary"></v-autocomplete>
              
              <draggable v-if="grupoDos" v-model="grupoDos" tag="div" ghost-class="ghost" chosen-class="chosen" animation="100" :group="{ name: 'pregunta', pull: false, put: true }">
                <v-card border v-for="item in grupoDos" :key="item.idPregunta" class="mt-2 mb-2 pa-2 elevation-2">
                  {{ item.descripcion }}
                </v-card>
              </draggable>
            </div>
          </div>
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="clear()">Cerrar</v-btn>
            <v-btn class="me-2" :color="!cambio ? 'grey' : 'blue'" :disabled="!cambio" text @click="deshacer">Deshacer</v-btn>
            <v-btn :color="!cambio ? 'grey' : 'success'" :disabled="!cambio" depressed @click="cambiarGrupo()">Aceptar</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import draggable from 'vuedraggable';
import { mapState } from "vuex";

export default {
  name: "GrupoPreguntas",
  components: {
    draggable: draggable,
  },
  data() {
    return {
      tituloDialogo: 'Agregar',
      editar: false,
      dialogoPreguntas: false,
      dialogoCambiarEstado: false,
      dialogoCambiarGrupo: false,
      buscar: {
        grupo: '',
        tipo: null,
        estado: null,
      },
      estados: [
        { text: 'Activo', value: false },
        { text: 'Inactivo', value: true }
      ],
      crearGrupo: {
        descripcion: '',
        tipo: null,
        preguntas: [{ descripcion: '', priorizacion: 1, eliminado:false }]
      },
      tipoPreguntas: [
        { text: 'Desempeño', value: 'D' },
        { text: 'Potencial', value: 'P' }
      ],
      cardGrupos: {
        items: [],
        desempeno: [],
        potencial: [],
        opciones : [10, 20, 30],
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      },
      rules: {
        required: [
          v => !!v || "Este campo es requerido.",
        ],
      },
      usuario: '',
      preguntasSeleccionadas: {},
      grupoSeleccionado: {},
      grupoUno: [],
      grupoDos: [],
      grupoDestino: null,
      grupoDosFinal: [],
      grupoDosInicial: [],
      grupoUnoInicial: [],
      preguntasCambiadas: [],
      cambio: false,
      autoGrow: false,
      mensajeEstado: '',
    }
  },
  mounted() {
    this.listarGrupoPreguntas();
    this.usuario = this.auth.username.toUpperCase();
    if (this.editar) {
      this.validarDuplicados();
    }
  },
  computed: {
    ...mapState(["auth", "enterprise"]),
    rangoItems() {
      const start = (this.cardGrupos.page - 1) * this.cardGrupos.itemsPerPage + 1;
      let end = this.cardGrupos.page * this.cardGrupos.itemsPerPage;
      if (end > this.cardGrupos.totalElements) {
        end = this.cardGrupos.totalElements;
      }
      return `${start} - ${end}`;
    },
    formValid() {
      const descripcionValida = this.crearGrupo.descripcion && this.crearGrupo.tipo;
      const preguntaValida = this.crearGrupo.preguntas[0]?.descripcion && this.preguntasLlenas;
      const sinDuplicados = !this.hayPreguntasDuplicadas();
      return descripcionValida && preguntaValida && sinDuplicados;
    },
    estado() {
      return this.cardGrupos.eliminado ? '¿Desea activar el grupo?' : '¿Desea inactivar el grupo?';
    },
    grupos() {
      return this.cardGrupos.items
      .filter(item => !item.eliminado && item.descripcion !== this.preguntasSeleccionadas.descripcion)
      .map(item => item.descripcion);
    },
    mover() {
      return !this.buscar.grupo  && this.buscar.tipo === null && this.buscar.estado === null;
    },
    preguntasLlenas() {
      return this.crearGrupo.preguntas.every(pregunta => pregunta.descripcion.trim() !== '');
    },
  },
  watch: {
    'buscar.estado': function () {
      this.cardGrupos.page = 1;
      this.listarGrupoPreguntas();
    },
    'buscar.tipo': function () {
      this.cardGrupos.page = 1;
      this.listarGrupoPreguntas();
    },
    'buscar.grupo': function () {
      this.cardGrupos.page = 1;
      this.listarGrupoPreguntas();
    },
    editar(nuevoValor) {
      if (nuevoValor && this.crearGrupo.preguntas.length > 0) {
        this.validarDuplicados();
      }
    }
  },
  methods: {
    listarGrupoPreguntas() {
      this.$http.get(`msa-hr/api/grupo-pregunta/listar`, {
        params: {
          page: this.cardGrupos.page - 1,
          size: this.cardGrupos.itemsPerPage,
          idEmpresa: this.enterprise.code,
          descripcion: `${this.buscar.grupo.toLowerCase()}`,
          tipoPreguntaValue: this.buscar.tipo,
          eliminado: this.buscar.estado
        }
      }).then((response) => {
        this.cardGrupos.items = response.data.content;
        this.cardGrupos.totalPage = response.data.totalPages;
        this.cardGrupos.totalElements = response.data.totalElements;
      }).catch((error) => {
        console.log(error);
      });
    },
    cambiarPrioridadGrupo() {
      const actualizaciones = this.cardGrupos.items.map((item, index) => ({
        idGrupoPregunta: item.idGrupoPregunta,
        descripcion: item.descripcion,
        empresa: {
          idEmpresa: this.enterprise.code,
        },
        eliminado: item.eliminado,
        tipoPreguntaValue: item.tipoPreguntaValue,
        priorizacion: index + 1,
        lastmodifiedby: this.usuario
      }));
      const promesa = actualizaciones.map((actualizaciones) =>
      this.$http.post(`msa-hr/api/grupo-pregunta/guardar`, actualizaciones, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
    )
    Promise.all(promesa)
      .then(() => {
        this.listarGrupoPreguntas();
      }).catch((error) => {
        console.log(error);
      })
    },
    agregarCampoPregunta() {
      const newIndex = this.crearGrupo.preguntas.length + 1;
      this.crearGrupo.preguntas.push({ descripcion: '', priorizacion: newIndex, eliminado:false });
    },
    eliminarCampoPregunta(index) {
      this.crearGrupo.preguntas.splice(index, 1);
    },
    cambiarPrioridadPreguntas() {
      this.crearGrupo.preguntas.forEach((pregunta, index) => {
        pregunta.priorizacion = index + 1;
      });
    },
    agregarGrupo() {
      const pregunta = {
        descripcion: this.crearGrupo.descripcion,
        priorizacion: this.cardGrupos.items.length + 1,
        tipoPreguntaValue: this.crearGrupo.tipo,
        empresa: {
          idEmpresa: this.enterprise.code,
        },
        eliminado: false,
        createdby: this.usuario,
        lastmodifiedby: this.usuario,
        preguntas: this.crearGrupo.preguntas.map(pregunta => ({
          descripcion: pregunta.descripcion,
          priorizacion: pregunta.priorizacion,
          eliminado: pregunta.eliminado,
          createdby: this.usuario,
          lastmodifiedby: this.usuario,
        }))
      }
      this.$http.post(`msa-hr/api/grupo-pregunta/guardar`, pregunta)
      .then(() => {
        this.listarGrupoPreguntas();
        this.dialogoPreguntas = false;
        this.clear();
      }).catch((error) => {
        console.log(error);
      });
    },
    abrirDialogoEditar(item) {
      this.tituloDialogo = 'Editar'
      this.editar = true;
      this.dialogoPreguntas = true;
      this.crearGrupo = JSON.parse(JSON.stringify(item));
      this.preguntasSeleccionadas = item;
      this.crearGrupo.tipo = item.tipoPreguntaValue;
    },
    editarGrupo() {
      const pregunta = {
        idGrupoPregunta: this.preguntasSeleccionadas.idGrupoPregunta,
        descripcion: this.crearGrupo.descripcion,
        priorizacion: this.preguntasSeleccionadas.priorizacion,
        tipoPreguntaValue: this.crearGrupo.tipo,
        empresa: {
          idEmpresa: this.enterprise.code,
        },
        eliminado: false,
        lastmodifiedby: this.usuario,
        preguntas: this.crearGrupo.preguntas.map(pregunta => ({
          idPregunta: pregunta.idPregunta,
          descripcion: pregunta.descripcion,
          priorizacion: pregunta.priorizacion,
          eliminado: pregunta.eliminado,
          lastmodifiedby: this.usuario,
        }))
      }
      this.$http.post(`msa-hr/api/grupo-pregunta/guardar`, pregunta)
      .then(() => {
        this.listarGrupoPreguntas();
        this.dialogoPreguntas = false;
        this.clear();
      }).catch((error) => {
        console.log(error);
      });
    },
    abrirDialogoEstado(item) {
      this.dialogoCambiarEstado = true
      this.preguntasSeleccionadas = item;
      this.mensajeEstado = this.preguntasSeleccionadas.eliminado === false ? '¿Desea inactivar el grupo?' : '¿Desea activar el grupo?';
    },
    cambiarEstado() {
      this.preguntasSeleccionadas.eliminado = (this.preguntasSeleccionadas.eliminado == false) ? true : false;
      const cambiarEstado = {
        idGrupoPregunta: this.preguntasSeleccionadas.idGrupoPregunta,
        eliminado: this.preguntasSeleccionadas.eliminado,
        descripcion: this.preguntasSeleccionadas.descripcion,
        priorizacion: this.preguntasSeleccionadas.priorizacion,
        tipoPreguntaValue: this.preguntasSeleccionadas.tipoPreguntaValue,
        empresa: {
          idEmpresa: this.enterprise.code,
        },
        lastmodifiedby: this.usuario,
        preguntas: this.preguntasSeleccionadas.preguntas.map(pregunta => ({
          idPregunta: pregunta.idPregunta,
          descripcion: pregunta.descripcion,
          priorizacion: pregunta.priorizacion,
          eliminado: false,
          lastmodifiedby: this.usuario,
        }))
      }
      this.$http.post(`msa-hr/api/grupo-pregunta/guardar`, cambiarEstado)
      .then(() => {
        this.listarGrupoPreguntas();
        this.dialogoPreguntas = false;
        this.clear();
      }).catch((error) => {
        console.log(error);
      });
    },
    cambiarEstadoPregunta(pregunta){
      pregunta.eliminado = !pregunta.eliminado;
    },
    abrirDialogoCambiarGrupo(item) {
      this.preguntasSeleccionadas = item;
      this.dialogoCambiarGrupo = true;
      this.grupoUno = item.preguntas;
      this.grupoUnoInicial = this.grupoUno;
    },
    moverPregunta(evt) {
      const { removed } = evt;
      if (this.grupoUno.length === 0) {
        if (removed) {
          this.grupoUno.splice(removed.newIndex, 0, removed.element);
          this.grupoDos = this.grupoDosFinal;
        }
      } else {
        if (removed) {
          this.preguntasCambiadas.push(removed.element);
          this.cambio = true;
        }
        this.grupoDosFinal = this.grupoDos;
      }
    },
    deshacer() {
      this.grupoDos = this.grupoDosInicial;
      this.grupoUno = this.grupoUnoInicial;
      this.preguntasCambiadas = [];
      this.cambio = false;
    },
    traerPreguntas() {
      if (this.grupoDestino){
        const grupo = this.cardGrupos.items.find(item => item.descripcion === this.grupoDestino);
        this.grupoSeleccionado = grupo;
        this.grupoDos = grupo.preguntas;
        this.grupoDosInicial = this.grupoDos;
        this.grupoDosFinal = this.grupoDos;
      } else {
        this.grupoDos = [];
        this.grupoSeleccionado = {};
      }
    },
    cambiarGrupo() {
      const grupo = {
        idGrupoPregunta: this.grupoSeleccionado.idGrupoPregunta,
        descripcion: this.grupoSeleccionado.descripcion,
        priorizacion: this.grupoSeleccionado.priorizacion,
        tipoPreguntaValue: this.grupoSeleccionado.tipoPreguntaValue,
        empresa: {
          idEmpresa: this.enterprise.code,
        },
        eliminado: false,
        lastmodifiedby: this.usuario,
        preguntas: this.preguntasCambiadas.map(p => ({
          idPregunta: p.idPregunta,
          descripcion: p.descripcion,
          priorizacion: p.priorizacion,
          eliminado: false,
          lastmodifiedby: this.usuario,
        }))
      }
      this.$http.post(`msa-hr/api/grupo-pregunta/guardar`, grupo)
      .then(() => {
        this.listarGrupoPreguntas();
        this.dialogoPreguntas = false;
        this.clear();
      }).catch((error) => {
        console.log(error);
      });
    },
    obtenerTipo(item) {
      const tipo = this.tipoPreguntas.find(tipoPregunta => tipoPregunta.value === item);
      return tipo ? tipo.text : '';
    },
    clear() {
      this.dialogoPreguntas = false;
      this.dialogoCambiarEstado = false;
      this.dialogoCambiarGrupo = false;
      setTimeout(() => {
        this.grupoDestino = null;
        this.grupoUno = [];
        this.grupoDos = [];
        this.preguntasSeleccionadas = {};
        this.tituloDialogo = 'Agregar';
        this.crearGrupo = {
          descripcion: '',
          tipo: null,
          preguntas: [{ descripcion: '', priorizacion: 1, eliminado:false }]
        };
        this.editar = false;
        if (this.$refs.preguntasForm) {
          this.$refs.preguntasForm.reset();
        }
      }, 800);
      this.cambio = false;
      this.grupoSeleccionado = {};
      this.grupoDosFinal = [];
      this.grupoDosInicial = [];
      this.grupoUnoInicial = [];
      this.preguntasCambiadas = [];
    },
    validarDuplicados() {
      if (!this.crearGrupo.preguntas || !this.crearGrupo.preguntas.length === 0) {
        return;
      }

      const descripciones = this.crearGrupo.preguntas.map(p => p.descripcion?.trim()).filter(Boolean);
      const conteo = descripciones.reduce((acc, desc) => {
        acc[desc] = (acc[desc] || 0) + 1;
        return acc;
      }, {});

      this.crearGrupo.preguntas.forEach((pregunta) => {
        const descripcion = pregunta.descripcion?.trim();
        pregunta.error = conteo[descripcion] > 1 ? "Esta pregunta ya existe" : "";
      });
    },
    hayPreguntasDuplicadas() {
      const seen = new Set();
      return this.crearGrupo.preguntas.some((pregunta) => {
        const descripcion = pregunta.descripcion?.trim();
        if (seen.has(descripcion)) {
          return true;
        }
        seen.add(descripcion);
        return false;
      });
    }
  },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 86vh;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

.contenedor-cards {
  width: 100%;
  max-width: none;
}

.contenido-cards {
  max-height: 60vh;
  overflow-y: auto;
  border: solid 0.5px;
  border-color: #e0e0e0;
}

.paginado {
  font-size: small;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding-top: 16px;
}

.paginado button {
  border: none;
  margin: 0 7px;
}

.items {
  margin: 0 28px;
}

.chosen {
  transform: scale(0.99);
}

.ghost {
  opacity: 0.3;
  background: #d9dbdcc4;
}

.preguntas {
  color: #0d47a1;
  display: flex;
  justify-content: center;
}

.linea {
  height: 1px;
  background-color: #0d47a1;
}

.size {
  width: 50%;
}

.info-card {
  color: black;
  padding: 3px;
  font-weight: bold;
}

.campo {
  width: 33.33%;
}

.size-card {
  max-height: 70vh;
  overflow-y: auto;
}

::v-deep .select .v-input__control {
  width: 75px !important;
}
</style>